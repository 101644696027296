import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import CngSection from 'src/components/cngcomponents/CngSection'
import Response from './Response'
import StatusNotification from './StatusNotification'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import NaCodeMaintenanceApiURLs from "src/apiUrls/NaCodeMaintenanceApiUrls"

const { CngTabs } = components
const {
  filter: { EQUAL }
} = constants

function ResponseLog(props) {
  const { manifestId } = props

  const { fetchRecords } = useServices()

  const downloadFile = () => {

    fetchRecords.execute(
      NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
      {
        customData: { codeMType: "CodeMasterCode" },
        filters: [
          {
            field: 'active',
            operator: EQUAL,
            value: 'true'
          },
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACE_OCN_DISPOSITION_CODE'
          }
        ],
        sorts : [
            { field: 'id', direction: 'ASC' }
        ]
      },
      (res) => {
        let result = "";
          res.content.forEach((item) => { 
            result += item.code;

            if(item.code.length == 2){
              result += "  ";
            }else if(item.code.length == 3){
              result += " ";
            }
            result += item.descriptionEn
            result += "\n";
          })

          const blob = new Blob([result], { type: "text/txt" })
          const element = document.createElement("a");
          element.href = window.URL.createObjectURL(blob)
          element.download = "DispositionCodeList.txt";
          document.body.appendChild(element);
          element.click();
          element.parentNode.removeChild(element);
      },
      (error) => {
        console.log(error)
      }
    )

  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title="Response">
          <Response
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title="Status Notification">
          <StatusNotification
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <Link 
          onClick={() => downloadFile()}>
          Disposition Code List
        </Link>
      </Grid>
    </Grid>
  )
}

export default ResponseLog
