import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import AceOcnBillofladingKeys from 'src/constants/locale/key/AceOcnBilloflading'
import AceOcnVesselDepartureKeys from 'src/constants/locale/key/AceOcnVesselDeparture'
import AceOcnVesselDepartureResponseKeys from 'src/constants/locale/key/AceOcnVesselDepartureResponse'
import AceOcnVesselArrivalKeys from 'src/constants/locale/key/AceOcnVesselArrival'
import AceOcnVesselArrivalResponseKeys from 'src/constants/locale/key/AceOcnVesselArrivalResponse'

const ACEOceanTranslationContext = React.createContext()

const ACEOceanTranslationConsumer = ACEOceanTranslationContext.Consumer

const ACEOceanTranslationProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.ACE_OCN_MANIFEST,
        Namespace.ACE_OCN_BILLOFLADING,
        Namespace.ACE_OCN_VESSEL_DEPARTURE,
        Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,
        Namespace.ACE_OCN_VESSEL_ARRIVAL,
        Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE
    ])

    const translation = {
        BOL: {
            aceOcnBilloflading: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TITLE),
            voyageReference: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.VOYAGE_REFERENCE),
            manifestId: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.MANIFEST_ID),
            billIndicator: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.BILL_INDICATOR),
            billNumber: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.BILL_NUMBER),
            issuerScac: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ISSUER_SCAC),
            oceanBillNumber: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.OCEAN_BILL_NUMBER),
            notifyScac: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.NOTIFY_SCAC),
            carrierScac: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CARRIER_SCAC),
            vesselName: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.VESSEL_NAME),
            voyageNumber: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.VOYAGE_NUMBER),
            vesselCountry: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.VESSEL_COUNTRY),
            portOfDischarge: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.PORT_OF_DISCHARGE),
            etaDateArr: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.E_T_A),
            placeofReciept: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.PLACEOF_RECIEPT),
            portOfLoading: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.PORT_OF_LOADING),
            lastForeignPort: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.LAST_FOREIGN_PORT),
            partyId: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.PARTY_ID),
            submittedBy: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.SUBMITTED_BY),
            submittedDate: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.SUBMITTED_DATE),
            templateName: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TEMPLATE_NAME),
            templateFlag: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TEMPLATE_FLAG),
            modeOfSubmission: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.MODE_OF_SUBMISSION),
            status: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.STATUS),
            totalPackages: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TOTAL_PACKAGES),
            totalPackagesUom: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TOTAL_PACKAGES_UOM),
            totalGrossWeight: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TOTAL_GROSS_WEIGHT),
            totalGrossWeightUom: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TOTAL_GROSS_WEIGHT_UOM),
            totalVolume: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TOTAL_VOLUME),
            totalValueUom: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.TOTAL_VALUE_UOM),
            messageFunCode: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.MESSAGE_FUN_CODE),
            createdDate: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CREATED_DATE),
            editButton: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.EDIT_BUTTON),
            deleteButton: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DELETE_BUTTON),
            editDisabled: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.EDIT_DISABLED),
            deleteDisabled: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DELETE_DISABLED),
            saveFlag: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.SAVE_FLAG),
            createdByLoginId: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CREATED_BY_LOGIN_ID),
            dispositionCode: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DISPOSITION_CODE),
            
            commercialParties: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.TITLE),
            partyDropDown: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.PARTY_DROPDOWN),
            partyIdentifier: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.PARTY_IDENTIFIER),
            partyType: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.PARTY_TYPE),
            partyName: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.PARTY_NAME),
            address1: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.ADDRESS1),
            address2: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.ADDRESS2),
            address3: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.ADDRESS3),
            telNo: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.CommercialParties.TEL_NO),
            containerDetails: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.TITLE),
            containerNumber: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.CONTAINER_NUMBER),
            typeCode: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.TYPE_CODE),
            descriptionCode: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.DESCRIPTION_CODE),
            sealNumber: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.SEAL_NUMBER),
            serviceCode: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.SERVICE_CODE),
            containerStatus: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.CONTAINER_STATUS),
            goodsDetails: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.TITLE),
            containerId: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.CONTAINER_ID),
            containerDesc: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.CONTAINER_DESC),
            goodsDesc: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.GOODS_DESC),
            containerNo: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.ContainerDetails.CONTAINER_NUMBER),
            numberOfPackages: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.NUMBER_OF_PACKAGES),
            packageUom: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.PACKAGE_UOM),
            marksAndNum: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.MARKS_AND_NUM),
            grossWeight: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.GROSS_WEIGHT),
            grossWeightUom: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.GROSS_WEIGHT_UOM),
            volume: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.VOLUME),
            volumeUOM: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.GoodsDetails.VOLUME_U_O_M),
            dGgoodsDetails: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.TITLE),
            goodsDetailsId: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.GOODS_DETAILS),
            imdgNo: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.IMDG_NO),
            flashPointTemp: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.FLASH_POINT_TEMP),
            dgCode: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.DG_CODE),
            dgClass: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.DG_CLASS),
            dgCodeQual: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.DG_CODE_QUAL),
            hazardousDesc: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.HAZARDOUS_DESC),
            hazardousPageNo: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.HAZARDOUS_PAGE_NO),
            materialDesc: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.MATERIAL_DESC),
            materialClass: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.MATERIAL_CLASS),
            emergencyContact: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.EMERGENCY_CONTACT),
            negative: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.DGgoodsDetails.NEGATIVE),

            bolScacInfo: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.BOL_SCAC_INFO),
            voyageScacInfo: translate(Namespace.ACE_OCN_BILLOFLADING, AceOcnBillofladingKeys.VOYAGE_REF_SCAC_INFO),

        },
        VESSEL_DEPARTURE : {
            id : translate( Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.ID), 
            aceOcnVesselDeparture : translate( Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.TITLE), 
            manifestHeaderId : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.MANIFEST_HEADER_ID),
            carrierScac : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.CARRIER_SCAC),
            vesselName : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.VESSEL_NAME),
            voyageNumber : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.VOYAGE_NUMBER),
            vesselCountry : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.VESSEL_COUNTRY),
            portOfDischarge : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.PORT_OF_DISCHARGE),
            departureDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.DEPARTURE_DATE),
            departureTime : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.DEPARTURE_TIME),
            lastForeignPort : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.LAST_FOREIGN_PORT),
            noOfBl : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.NO_OF_BL),
            departureStatus : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.DEPARTURE_STATUS),
            responseDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.RESPONSE_DATE),
            vesselCode : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.VESSEL_CODE),
            entityNo : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.ENTITY_NO),
            modeOfTransport : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.MODE_OF_TRANSPORT),
            eta : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.ETA),
            partyId : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.PARTY_ID),
            submittedBy : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.SUBMITTED_BY),
            submittedDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.SUBMITTED_DATE),
            createdBy : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.CREATED_BY),
            createdDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.CREATED_DATE),
            updatedBy : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.UPDATED_BY),
            updatedDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.UPDATED_DATE),
            createVesselDeparture : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE,AceOcnVesselDepartureKeys.CREATE_VESSEL_DEPARTURE),
            editButton: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.EDIT_BUTTON),
            deleteButton: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.DELETE_BUTTON),
            editSentRecord: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.EDIT_SENT_RECORD),
            deleteNotDraftRecord: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.DELETE_NOT_DRAFT_RECORD),
            createVesselDepartureTab: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.CREATE_VESSEL_DEPARTURE_TAB),
            viewVesselDepartureTab: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.VIEW_VESSEL_DEPARTURE_TAB),
            viewVesselDepartureResponseTab: translate(Namespace.ACE_OCN_VESSEL_DEPARTURE, AceOcnVesselDepartureKeys.VIEW_VESSEL_DEPARTURE_RESPONSE_TAB),

            //Response
            aceOcnVesselDepartureResponse : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TITLE),
            ocnVesselDepartureId : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.OCN_VESSEL_DEPARTURE_ID),
            transactionId : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TRANSACTION_ID),
            cbpPort : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.CBP_PORT),
            mnfSeqNo : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.MNF_SEQ_NO),
            errorMsg : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.ERROR_MSG),
            carrierCode : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.CARRIER_CODE),
            transmissionDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TRANSMISSION_DATE),
            totalMnfRead : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_MNF_READ),
            totalPortRead : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_PORT_READ),
            totalBillRead : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_BILL_READ),
            totalHouseBillRead : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_HOUSE_BILL_READ),
            totalAmendmentRead : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_AMENDMENT_READ),
            totalH01RecordInput : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_H01_RECORD_INPUT),
            totalReject : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_REJECT),
            totalAccept : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_ACCEPT),
            totalRecordRead : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.TOTAL_RECORD_READ),
            receivedDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.RECEIVED_DATE),
            cbpBatchNo : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.CBP_BATCH_NO),
            cbpProcessedDate : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.CBP_PROCESSED_DATE),
            remarks : translate(Namespace.ACE_OCN_VESSEL_DEPARTURE_RESPONSE,AceOcnVesselDepartureResponseKeys.REMARKS)
        },
        VESSEL_ARRIVAL: {
            id : translate( Namespace.ACE_OCN_VESSEL_ARRIVAL,AceOcnVesselArrivalKeys.ID), 
            aceOcnVesselArrival: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.TITLE),
            manifestHeaderId: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.MANIFEST_HEADER_ID),
            carrierScac: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.CARRIER_SCAC),
            vesselName: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.VESSEL_NAME),
            voyageNumber: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.VOYAGE_NUMBER),
            vesselCountry: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.VESSEL_COUNTRY),
            portOfDischarge: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.PORT_OF_DISCHARGE),
            arrivalDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.ARRIVAL_DATE),
            arrivalTime: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.ARRIVAL_TIME),
            lastForeignPort: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.LAST_FOREIGN_PORT),
            noOfBl: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.NO_OF_BL),
            arrivalStatus: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.ARRIVAL_STATUS),
            responseDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.RESPONSE_DATE),
            modeOfTransport: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.MODE_OF_TRANSPORT),
            eta: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.ETA),
            partyId: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.PARTY_ID),
            submittedBy: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.SUBMITTED_BY),
            submittedDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.SUBMITTED_DATE),
            createdBy: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.CREATED_BY),
            createdDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.CREATED_DATE),
            updatedBy: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.UPDATED_BY),
            updatedDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.UPDATED_DATE),
            createVesselArrival: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.CREATE_VESSEL_ARRIVAL),
            editButton: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.EDIT_BUTTON),
            deleteButton: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.DELETE_BUTTON),
            editSentRecord: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.EDIT_SENT_RECORD),
            deleteNotDraftRecord: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.DELETE_NOT_DRAFT_RECORD),
            createVesselArrivalTab: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.CREATE_VESSEL_ARRIVAL_TAB),
            viewVesselArrivalTab: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.VIEW_VESSEL_ARRIVAL_TAB),
            viewVesselArrivalResponseTab: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL, AceOcnVesselArrivalKeys.VIEW_VESSEL_ARRIVAL_RESPONSE_TAB),

            //Response
            aceOcnVesselArrivalResponse: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TITLE),
            ocnVesselArrivalId: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.OCN_VESSEL_ARRIVAL_ID),
            transactionId: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TRANSACTION_ID),
            cbpPort: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.CBP_PORT),
            mnfSeqNo: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.MNF_SEQ_NO),
            errorMsg: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.ERROR_MSG),
            carrierCode: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.CARRIER_CODE),
            transmissionDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TRANSMISSION_DATE),
            totalMnfRead: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_MNF_READ),
            totalPortRead: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_PORT_READ),
            totalBillRead: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_BILL_READ),
            totalHouseBillRead: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_HOUSE_BILL_READ),
            totalAmendmentRead: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_AMENDMENT_READ),
            totalH01RecordInput: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_H01_RECORD_INPUT),
            totalReject: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_REJECT),
            totalAccept: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_ACCEPT),
            totalRecordRead: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.TOTAL_RECORD_READ),
            receivedDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.RECEIVED_DATE),
            cbpBatchNo: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.CBP_BATCH_NO),
            cbpProcessedDate: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.CBP_PROCESSED_DATE),
            remarks: translate(Namespace.ACE_OCN_VESSEL_ARRIVAL_RESPONSE, AceOcnVesselArrivalResponseKeys.REMARKS)
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <ACEOceanTranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </ACEOceanTranslationContext.Provider>
    )
}

export default ACEOceanTranslationProvider

export { ACEOceanTranslationContext, ACEOceanTranslationConsumer }
