import { Box, Card, Chip, Grid, Radio, Tooltip, Typography, useTheme } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import AceOcnVesselArrivalApiUrls from 'src/apiUrls/AceOcnVesselArrivalApiUrls'
import React, { useEffect, useRef, useState, useContext } from 'react'
import AceOcnVesselArrivalKeys from 'src/constants/locale/key/AceOcnVesselArrival'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetFilterByUserIdCriteria } from 'src/common/FileForUserCommon'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import moment from 'moment-timezone'
import { getStatusMetadata } from '../../../common/NACommon'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import TemplateSelectDialogWithOptionACEOceanVesselArrival from './manifest/TemplateSelectDialogWithOptionACEOceanVesselArrival'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { BETWEEN, EQUAL, IN, LIKE, NOT_EQUAL }
} = constants

function TablePage(props) {
  const {
    history,
    location,
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  
  const [lookups, setLookups] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    arrival: null
  })

  const tableRef = useRef(null)
  const [manifestTemplateDialog, setManifestTemplateDialog] = useState(false)
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            {
              field: 'set_indicator',
              operator: EQUAL,
              value: 'IFX_US'
            }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(([status, firstExpectedPort]) => {
      setLookups({
        status,
        firstExpectedPort
      })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: "id",
      sortKey: "id",
      title: getTranslatedText("VESSEL_ARRIVAL","id"),
    },        
    {
      field: "carrierScac",
      sortKey: "carrierScac",
      title: getTranslatedText("VESSEL_ARRIVAL", "carrierScac"),
    },
    {
      field: "vesselName",
      sortKey: "vesselName",
      title: getTranslatedText("VESSEL_ARRIVAL", "vesselName"),
    },
    {
      field: "voyageNumber",
      sortKey: "voyageNumber",
      title: getTranslatedText("VESSEL_ARRIVAL", "voyageNumber"),
    },
    {
      field: "portOfDischarge",
      sortKey: "portOfDischarge",
      title: getTranslatedText("VESSEL_ARRIVAL", "portOfDischarge"),
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {getLookupValue('firstExpectedPort', data.portOfDischarge)}
            <TinyChip label={data.portOfDischarge} variant='outlined' />
          </Typography>
        </Box>
      )
    },
    {
      field: "arrivalDate",
      sortKey: "arrivalDate",
      title: getTranslatedText("VESSEL_ARRIVAL", "arrivalDate"),
      render: (data) => data.arrivalDate && moment(data.arrivalDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "arrivalStatus",
      sortKey: "arrivalStatus",
      title: getTranslatedText("VESSEL_ARRIVAL", "arrivalStatus"),
      render: (data) => {
        const status = getStatusMetadata(data.arrivalStatus)

        return (
          <>
            <Box mt={0.5}>
              <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}              >
                <Chip
                  label={status.label}
                  size='small'
                  style={{
                    backgroundColor: status.color,
                    color: status.contrastColor
                  }}
                />
              </Typography>
            </Box>
          </>
        )
      }
    },
    {
      field: "submittedDate",
      sortKey: "submittedDate",
      title: getTranslatedText("VESSEL_ARRIVAL", "submittedDate"),
      render: (data) => data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "responseDate",
      sortKey: "responseDate",
      title: getTranslatedText("VESSEL_ARRIVAL", "responseDate"),
      render: (data) => data.responseDate && moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  const filters = [
    {
      name: "id",
      label: getTranslatedText("VESSEL_ARRIVAL","id"),
      type: 'textfield',
      operatorType: EQUAL,
    },      
    {
      name: "carrierScac",
      label: getTranslatedText("VESSEL_ARRIVAL", "carrierScac"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "vesselName",
      label: getTranslatedText("VESSEL_ARRIVAL", "vesselName"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "voyageNumber",
      label: getTranslatedText("VESSEL_ARRIVAL", "voyageNumber"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "portOfDischarge",
      label: getTranslatedText("VESSEL_ARRIVAL", "portOfDischarge"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "arrivalDate",
      label: getTranslatedText("VESSEL_ARRIVAL", "arrivalDate"),
      type: 'daterange',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText("VESSEL_ARRIVAL", "arrivalStatus"),
      type: 'checkbox',
      name: 'arrivalStatus',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: '1005',
          filterValue: { value: '1005' }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: '1000',
          filterValue: { value: '1000' }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: '1008',
          filterValue: { value: '1008' }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: '1001',
          filterValue: { value: '1001' }
        },
        {
          fullWidth: true,
          label: 'Modification Rejected',
          value: '1007',
          filterValue: { value: '1007' }
        },
        {
          fullWidth: true,
          label: 'Cancel Accepted',
          value: '1003',
          filterValue: { value: '1003' }
        },
        {
          fullWidth: true,
          label: 'Cancel Rejected',
          value: '1004',
          filterValue: { value: '1004' }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: '1006',
          filterValue: { value: '1006' }
        }
      ]
    },
    {
      name: "submittedDate",
      label: getTranslatedText("VESSEL_ARRIVAL", "submittedDate"),
      type: 'daterange',
      operatorType: BETWEEN
    },
    {
      name: "responseDate",
      label: getTranslatedText("VESSEL_ARRIVAL", "responseDate"),
      type: 'daterange',
      operatorType: BETWEEN
    }
  ]

  function handleDeleteArrival() {
    if (confirmDialog.arrival) {
      deleteRecord.execute(
        AceOcnVesselArrivalApiUrls.DELETE,
        confirmDialog.arrival,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, arrival: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong while deleting record.')
        }
      )
    }
  }

  useEffect(() => {
    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])

  let filter = GetFilterByUserIdCriteria("ACEOCN")

  return (
    <>
      {sbPartyIdFetched && (<Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setManifestTemplateDialog(true)
            },
            label: getTranslatedText("VESSEL_ARRIVAL", "createVesselArrival"),
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AceOcnVesselArrivalApiUrls.EXPORT }}
        fetch={{ url: AceOcnVesselArrivalApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.arrivalStatus == '1008' || rowData.arrivalStatus === "1000") {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => (rowData.arrivalStatus == '1008' || rowData.arrivalStatus === "1000"),
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: getTranslatedText("VESSEL_ARRIVAL", "editButton"),
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              (rowData.arrivalStatus == '1008' || rowData.arrivalStatus === "1000")
                ? getTranslatedText("VESSEL_ARRIVAL", "editSentRecord")
                : null
          },
          {
            disabled: (data) => data.arrivalStatus != '1005',
            label: getTranslatedText("VESSEL_ARRIVAL", "deleteButton"),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, arrival: data }),
            tooltip: (rowData) =>
              rowData.arrivalStatus != '1005'
                ? getTranslatedText("VESSEL_ARRIVAL", "deleteNotDraftRecord")
                : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        selectActions={[

        ]}
      />)}
      <TemplateSelectDialogWithOptionACEOceanVesselArrival
        manifestTemplateDialog={manifestTemplateDialog}
        handleClose={() => setManifestTemplateDialog(false)}
        title={getTranslatedText("VESSEL_ARRIVAL", "createVesselArrival")}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, arrival: null })}
        confirmDialog={handleDeleteArrival}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText("VESSEL_ARRIVAL", "deleteButton")}
      />
    </>
  )
}

export default TablePage
